<template>
    <div>
        <v-btn dense color="primary" @click="editItem()">
            ADD
        </v-btn>
        <v-dialog v-model="dialogEdit" max-width="1200px">
            <v-card>
                <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" lg="6" xl="6"
                        v-for="(key, index) in keys"
                        :key=index>
                            <template v-if="key.type==='textField'">
                                <v-text-field
                                    :label="key.label"
                                    v-model="key.valueInput"
                                >
                                </v-text-field>
                            </template>
                            <template v-else-if="key.type==='SelectionandTextField'">
                                <!-- <v-select
                                    v-model="key.valueInput"
                                    multiple
                                    dense
                                    :label="key.label"
                                    :items="key.selections"

                                >
                                 -->

                                <!-- </v-select> -->
                                <SelectionandTextField
                                    :selections = "key.selections"
                                    :label="key.label"
                                    :keyname="key.keyname"
                                    ref="SelectandTF"
                                />
                            </template>
                        </v-col>
                    </v-row>
                </v-container>
                    <!-- <v-col>
                        <v-alert text class="mt-2 mb-0" color="info" icon="mdi-cloud-alert" border="left">
                            <p class="mb-0"> <strong> Api Code</strong>: Option field, used by particular LP api service </p>
                            <p class="mb-0"> <strong> Symbol</strong>: LP symbol name </p>
                            <p class="mb-0"> <strong> Pk</strong>: Symbol name used by pk </p>
                            <p class="mb-0"> <strong> Ratio</strong>: The factor that used between lp and broker, eg: The volume ratio of FXCM to broker itself is 10:0, then put 10 as value in ratio field</p>
                        </v-alert>
                    </v-col> -->
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="close" >
                    Cancel
                </v-btn>
                <v-btn color="green darken-1" text @click="save" :loading="saving">
                    Save
                </v-btn>
                </v-card-actions>
                </v-card>
        </v-dialog>
    </div>
</template>
<script>

import SelectionandTextField from "@components/input/SelectionandTextField";

export default {
    components: {
        SelectionandTextField
    },
    props: {
        keys: Array
    },
    created () {
        console.log(this.keys);
    },
    data () {
        return {
            dialogEdit: false,
            loading: false,
            saving: false
        };
    },

    methods: {
        editItem () {
            this.dialogEdit = true;
            this.loading = true;
        },
        close () {
            this.loading = false;
            this.dialogEdit = false;
        },
        save () {
            this.saving = true;
            // console.log(this.keys);

            const backValues = this.$refs.SelectandTF[0].saveTextField();
            // console.log(backValues);
            const valueValid = backValues[0];
            const keyname = backValues[1];
            const values = backValues[2];

            if (valueValid) {
                this.changeValue(keyname, values);
                this.$emit("saveValues", this.keys);
                setTimeout(() => {
                    this.saving = false;
                    this.close();
                }, 1000);
            } else {
                this.saving = false;
            }
        },
        changeValue (keyname, values) {
            for (const ele of this.keys) {
                if (ele.keyname === keyname) {
                    ele.valueInput = values;
                }
            }
        }
    }
};
</script>
