<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="warning" dark v-bind="attrs" v-on="on">{{ selectedBroker }}</v-btn>
        </template>
        <v-list>
            <v-list-item v-for="(item, index) in this.brokers" :key="index"
                @click="changeBroker(item.broker)">
                <v-list-item-title>{{ item.broker }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>


export default {
    data () {
        return {
            brokers: Array,
            selectedBroker: ""
        };
    },
    computed: {
    },
    methods: {
        changeBroker (broker) {
            this.selectedBroker = broker;
        }
    },
    created () {
        this.brokers = JSON.parse(localStorage.getItem("bp")).broker;
        if (this.selectedBroker === "") {
            this.selectedBroker = this.brokers[0].broker;
        }
    },
    mounted () {
    },
    watch: {
        selectedBroker (nv) {
            this.$emit("selectedBrokerChange", nv);
        }
    }
};
</script>
