<template>
    <div v-if="currentType==='Selections'">
        <v-select class="company-size-dropdown"
            :items="selections"
            v-model="valueSelectedArr"
            :label="label"
            dense
            multiple
            @change="selectedAction"
        >
        </v-select>
     </div>
     <div v-else>
       <v-text-field
            autofocus
            v-model="valueSelectedStr"
            :label="label"
            v-on:keyup.enter="appliances.first_name = newItem"
            append-icon="mdi-keyboard-return"
            @click:append="switchInputType"
            @keydown.enter="switchInputType"
        >
       </v-text-field>
      </div>
</template>

<script>
import { snackbar } from "@components/mixins/snackbar";

export default {
    props: {
        selections: Array,
        label: String,
        keyname: String
    },
    mixins: [snackbar],
    data () {
        return {
            currentType: "Selections",
            valueSelectedArr: [],
            valueSelectedStr: "",
            valueValid: true
        };
    },
    methods: {
        switchInputType () {
            // console.log(this.currentType);
            this.valueSelectedArr = [];
            this.currentType = "Selections";
            this.convertStringtoArr();
            // console.log(this.valueSelectedArr);
        },
        convertArrtoString (selectedValues) {
            // console.log(selectedValues);
            selectedValues = selectedValues.filter(ele => {
                return ele !== "Other";
            });

            if (selectedValues.length === 0) {
                return "";
            }

            const selectedPosArr = [];
            // console.log(this.selections, selectedValues);
            selectedValues.forEach(ele => selectedPosArr.push(this.selections.indexOf(ele)));
            // console.log(selectedPosArr);
            selectedPosArr.sort();
            // console.log(selectedValues, selectedPosArr);
            let previousItemIndex = selectedPosArr[0];
            let res = "";
            let flag = false;
            res += this.selections[previousItemIndex];
            for (let currentIndex = 1; currentIndex < selectedPosArr.length; currentIndex += 1) {
                if (selectedPosArr[currentIndex] - previousItemIndex !== 1) {
                    if (flag) {
                        res += "->";
                        res += this.selections[selectedPosArr[currentIndex - 1]];
                    }
                    res += ",";
                    flag = false;
                    res += this.selections[selectedPosArr[currentIndex]];
                } else {
                    flag = true;
                }
                if (currentIndex === selectedPosArr.length - 1) {
                    if (flag) {
                        res += "->";
                        res += this.selections[selectedPosArr[currentIndex]];
                        res += ",";
                    } else {
                        res += ",";
                    }
                }
                previousItemIndex = selectedPosArr[currentIndex];
            }
            if (selectedPosArr.length === 1) {
                res += ",";
            }
            return res;
        },

        convertStringtoArr () {
            this.valueSelectedStr = this.valueSelectedStr.replace(" ", "");
            const items = this.valueSelectedStr.split(",");
            for (const elemet of items) {
                if (elemet === "") {
                    continue;
                }
                const rangeEle = elemet.split("->");
                if (rangeEle.length === 2) {
                    const end = this.selections.indexOf(rangeEle[1]);
                    const start = this.selections.indexOf(rangeEle[0]);
                    if (start > end) {
                        this.snackBarDanger("Invaild Range, Starting server should less than end server");
                    } else {
                        for (let i = start; i <= end; i += 1) {
                            if (this.checkInputValue(this.selections[i])) {
                                this.valueValid = false;
                                this.addNewSelections(this.selections[i]);
                            };
                        }
                    }
                } else {
                    if (this.checkInputValue(elemet)) {
                        this.valueValid = false;
                        this.addNewSelections(elemet);
                    };
                }
            }
            // console.log(this.valueSelectedArr);
        },

        checkInputValue (value) {
            const splitedInput = value.split("-");
            if (splitedInput[0] !== "MT4") {
                this.snackBarDanger("Invaild Server Name: " + "'" + value + "'");
                return false;
            } else if (this.switchInputType.length === 2 && isNaN(parseInt(splitedInput[1]))) {
                this.snackBarDanger("Invaild Server Name: " + "'" + value + "'");
                return false;
            }
            return true;
        },

        selectedAction (value) {
            if (value.includes("Other")) {
                this.valueSelectedStr = this.convertArrtoString(value);
                this.currentType = "TextField";
                this.valueSelectedArr.filter(ele => ele !== "Other");
            }
        },

        addNewSelections (value) {
            if (!this.selections.includes(value)) {
                this.selections.push(value);
            }
            this.valueSelectedArr.push(value);
        },
        saveTextField () {
            if (this.currentType === "TextField") {
                this.convertStringtoArr();
            }
            return [this.valueValid, this.keyname, this.valueSelectedArr];
        }
    }
};
</script>
