<template>
    <div>
        <v-card>
            <Breadcrumbs />
            <v-row class="pa-4">
                <v-col lg="12" md="12" xs="12" cols="12">
                    <BrokerSwitcher @selectedBrokerChange="handleSelectedBroker"/>
                    <AddDatatoTable @saveValues="handleFormValues" :keys=keys style="float:right;"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col lg="12" md="12" xs="12" cols="12">
                    <v-data-table
                        dense
                        :headers="bookGroupRulesHeader"
                        :items="bookGroupRules"
                        v-model="selectedtoDelete"
                    >
                    <!-- <template v-slot:item.actions="{ item }">
                        <v-icon
                            small
                            @click="deleteItem(item)"
                        >
                            mdi-delete
                        </v-icon>
                        </template> -->
                    <template v-slot:item.active="{ item }">
                        <v-switch v-model="item.active" dense small class="mt-0 pt-0" style="height: 20px;" @change="editItemAcitive(item)">
                        </v-switch>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>

        <DeleteDatafromTable
            @changeDialog="handleDialogStatus"
            :dialogDelete="dialogDelete"
        />
    </div>
</template>
<script>

import { mapState, mapActions } from "vuex";
import BrokerSwitcher from "@components/button/BrokerSwitcher";
import AddDatatoTable from "@components/button/AddDatatoTable";
import DeleteDatafromTable from "@components/button/DeleteDatafromTable";
import Breadcrumbs from "@components/Breadcrumbs";

export default {
    components: {
        BrokerSwitcher,
        AddDatatoTable,
        DeleteDatafromTable,
        Breadcrumbs
    },
    data () {
        return {
            selectedBroker: String,
            loadParam: Object,
            deleteParam: {},
            dialogDelete: false,
            selectedtoDelete: [],
            createParam: {},
            keys: []
        };
    },
    computed: {
        ...mapState("BookGroupRules", ["bookGroupRules", "bookGroupRulesHeader", "keyReqNumber", "keyForUserInput", "serverSelection", "uniqueInputTypeforKeys"])
    },
    methods: {
        ...mapActions("BookGroupRules", ["loadBookGroupRules", "deleteBookGroupRules", "createBookGroupRules", "createHeader", "editBookGropuRules", "clearBookGroupRules"]),
        handleSelectedBroker (value) {
            console.log(value);
            this.selectedBroker = value;
            this.clearBookGroupRules();
            this.loadParam = { broker_name: this.selectedBroker };
            this.loadBookGroupRules(this.loadParam);
        },
        handleFormValues (value) {
            for (const key of this.keys) {
                let keyValue = key.valueInput;
                const keyName = key.keyname;
                if (this.keyReqNumber.includes(keyName)) {
                    keyValue = parseInt(keyValue);
                }
                this.createParam[keyName] = keyValue;
            }
            this.createParam.broker_name = this.selectedBroker;
            this.createBookGroupRules({ paramCreate: this.createParam, paramLoad: this.loadParam });
        },
        createForm () {
            const res = [];
            for (const key of this.keyForUserInput) {
                const temp = {};
                const label = key.split("_").map(ele => {
                    return ele[0].toUpperCase() + ele.slice(1);
                });
                console.log(key, this.uniqueInputTypeforKeys[key]);
                temp.label = label.join("");
                temp.type = this.uniqueInputTypeforKeys[key] || "textField";
                temp.keyname = key;
                temp.valueInput = "";
                if (temp.type === "SelectionandTextField") {
                    temp.valueInput = [];
                    temp.selections = this.serverSelection;
                }
                console.log(temp);
                res.push(temp);
            }
            this.keys = res;
            console.log(this.keys);
        },

        deleteItem () {
            this.dialogDelete = true;
        },
        handleDialogStatus (value) {
            if (value === "delete") {
                console.log(this.selectedtoDelete);
                const deleteId = this.selectedtoDelete.map(function (el) { return el.id; });
                console.log(deleteId);
                this.deleteParam["id"] = deleteId;
                this.deleteBookGroupRules({ paramDelete: this.deleteParam, paramLoad: this.loadParam });
            }
            this.dialogDelete = false;
        },

        editItemAcitive (item) {
            item.active = item.active ? 1 : 0;
            console.log(item);
            this.editBookGropuRules({ paramEdit: item, paramLoad: this.loadParam });
        }
    },
    created () {
        this.createForm();
    },
    mounted () {
        console.log(this.bookGroupRulesHeader);
    }
};
</script>
<style>
</style>
